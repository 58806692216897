import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'homepage',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/HomePage.vue')
  }]
},{
  path: '/services',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'services',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ServicesPage.vue')
  },{
    path: 'smart-solutions',
    name: 'Smart Solutions',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/services/SmartSolutionsServicesPage.vue')
  },{
    path: 'web',
    name: 'web services',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/services/WebServicesPage.vue')
  },{
    path: 'security',
    name: 'security services',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/services/SecurityServicesPage.vue')
  },{
    path: 'consulting',
    name: 'consulting services',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/services/ConsultServicesPage.vue')
  }]
}, {
  path: '/about',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'about',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/AboutPage.vue')
  }]
}, {
  path: '/contact',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ContactPage.vue')
  }]
}, {
  path: '/privacy-policy',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PrivacyPolicyPage.vue')
  }]
}, {
  path: '/cookie-policy',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'Cookie',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/CookiePolicyPage.vue')
  }]
}, {
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
