import Vue from 'vue'
import VueGtm from 'vue-gtm'
import router from '../router'
import config from '../configs'

const { gtmId } = config.analytics

if (gtmId) {
  Vue.use(VueGtm, {
    id: gtmId,
    vueRouter: router,
    debug: true
  })
}
